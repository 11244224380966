<template>
  <div class="codeID">
    <van-nav-bar
      title="证件照"
      left-arrow
      fixed
      @click-left="goBack"
      @click-right="isDropdownMenu = !isDropdownMenu"
    >
      <template #right>
        <van-icon size="22" name="apps-o" />
      </template>
    </van-nav-bar>
    <div
      class="downMenuModal"
      v-if="isDropdownMenu"
      @click="isDropdownMenu = false"
    >
      <div v-if="isDropdownMenu" class="dropdownMenu">
        <div class="menuItem" @click="$router.push('/')">
          <span>首页</span>
        </div>
        <div class="menuItem" @click="$router.push('/xuanke')">
          <span>选课中心</span>
        </div>
        <div class="menuItem" @click="$router.push('/tiku')">
          <span>题库</span>
        </div>
        <div class="menuItem" @click="$router.push('/homeLiveList')">
          <span>直播</span>
        </div>
        <div class="menuItem" @click="$router.push('/learning')">
          <span>学习中心</span>
        </div>
        <div class="menuItem" @click="$router.push('/photo')">
          <span>证件照</span>
        </div>
        <div class="menuItem" @click="downApp">
          <span>下载APP</span>
        </div>
      </div>
    </div>
    <div class="content-box">
      <img class="show_img" :src="downloadPhoto" />
      <div class="detail-box">
        <div class="shuoming-box">
          <div>请长按图片，保存到相册</div>
        </div>
        <div class="goTo-photo-box" @click="GoToPhoto">
          <div>点击选择我的证件照,查看证件照</div>
        </div>
        <div class="prom_title">提示:</div>
        <div class="prom_detail" style="margin-top: 5px">
          1、可通过手机USB数据线连接到电脑，找到照片文件夹，复制照片到电脑。
        </div>
        <div class="prom_detail">
          2、可通过邮件发送照片到邮箱，将照片保存到文件，发邮件时选择添加附件。
        </div>
        <div class="prom_detail">
          3、不要通过微信上传到电脑，微信会压缩改变照片的尺寸和大小。
        </div>
        <div class="prom_detail">
          4、您可以直接复制下方照片链接，在电脑端浏览器打开保存即可。
        </div>
        <div class="prom_detail">
          图片链接：<span style="color: red">{{ downloadPhoto }}</span>
        </div>
        <div class="prom_detail" style="width: 100%; height: 15px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { downLoadFree,h5Paywx } from "@/api/photo.js";
import { orderstatus } from "@/api/order.js";
export default {
  name: "downloadPhoto",
  data() {
    return {
      isDropdownMenu: false,
      downloadPhoto: "",
      activeList: {
        data: {
          filenames: [
            {
              preview_url: "",
            },
          ],
        },
        
      },
      listData: {},
      orderNo: "",
      state:""
    };
  },
  created() {
    
  },
  mounted(){
    this.init();
  },
  methods: {
    GoToPhoto(){
      this.$router.push({
        path:'/photo'
      })
    },
    downApp(){
      window.location.href = 'https://www.saen.com/app/';
    },
    goBack() {
      this.$router.go(-1);
    },
    getReuslt(){
      if(this.$route.query.payType == "intPay" || this.$route.query.type == "intPay"){
        this.downLoadImg();
      }else{
        let that = this;
        let formData = new FormData();
        formData.append("serviceId", localStorage.getItem("serviceId")); 
        formData.append("serviceType",4);
        orderstatus(formData).then((res) => {
          that.state = res.data.data;
          if(res.data.data == 1){
            that.downLoadImg();
          }else{
            window.location.replace("https://m.saen.com/payment");
          }
        });

      }
      
    },
    downLoadImg(){
      this.activeList = JSON.parse(localStorage.getItem("detail_makePhoto"));
      this.$toast.loading({
          message: "加载中...",
          forbidClick: true,
      });
      let downLoadata = new FormData();
      downLoadata.append(
        "idphotoName",
        this.activeList.data.filenames[0].img_name
      );
      downLoadata.append("orderNo", localStorage.getItem("orderNo"));
      downLoadFree(downLoadata).then((result) => {
          this.$toast.clear();
          console.log("res====", result.data.data);

          localStorage.setItem("downloadPhoto", result.data.data);
          this.downloadPhoto = result.data.data;
      });
    },
    init() {
      if(this.$route.query.type == "list"){
        this.downloadPhoto = localStorage.getItem("downloadPhoto");
      }else{
        this.getReuslt();
      }
      
      
    },
  },
};
</script>

<style lang="less" scoped>
.show_img {
  //padding: 0px 30px;
  margin-top: 20px;
  margin-left: 25%;
  width: 50%;
  height: 250px;
}
.content-box {
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
  width: 100%;
  height: calc(100vh - 46px);
  margin-top: 46px;
  background-color: #ffffff;
  overflow: hidden;
  overflow-y: auto;
}
.codeID {
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // margin-top: 46px;
  .detail-box {
    width: calc(100% - 50px);
    margin-left: 20px;
    background-color: white;
    border-radius: 4px;
    height: 45px;
    margin-bottom: 10px;
    .shuoming-box {
      padding: 10px;
      text-align: center;
      color: #ecb213;
      font-size: 18px;
      font-weight: bold;
    }
    .goTo-photo-box {
      padding: 10px;
      text-align: center;
      color: blue;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
    .prom_title {
      font-size: 14px;
      font-weight: 600;
      color: blue;
      padding-left: 10px;
    }
    .prom_detail {
      font-size: 12px;
      padding-left: 10px;
      color: #8d8989;
    }
  }
}
.downMenuModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  .dropdownMenu {
    position: fixed;
    right: 10px;
    top: 44px;
    background-color: #fff;
    z-index: 999;
    width: 100px;
    min-height: 200px;
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.5);

    .menuItem {
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      padding-left: 20px;
    }
    .menuItem5 {
      color: #ef8f27;
    }
  }
}
</style>
